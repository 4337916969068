import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "bg-white shadow rounded-lg divide-y divide-gray-200 mb-6"
};
const _hoisted_2 = {
  class: "bg-gray-50 px-4 py-5 sm:px-6 rounded-t-lg"
};
const _hoisted_3 = {
  class: "-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
};
const _hoisted_4 = {
  class: "ml-4 mt-2"
};
const _hoisted_5 = {
  class: "text-lg leading-6 font-medium text-gray-900"
};
const _hoisted_6 = {
  key: 0,
  class: "ml-4 mt-2 flex-shrink-0"
};
const _hoisted_7 = {
  class: "border-t border-gray-200 px-4 py-5 sm:p-0"
};
const _hoisted_8 = {
  class: "sm:divide-y sm:divide-gray-200"
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("h3", _hoisted_5, [_renderSlot(_ctx.$slots, "title")])]), _ctx.$slots.button ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_renderSlot(_ctx.$slots, "button")])) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_7, [_createElementVNode("dl", _hoisted_8, [_renderSlot(_ctx.$slots, "default")])])]);
}