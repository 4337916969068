import { api } from "@/api"
import BasePage from "@/utils/BasePage.js"

export async function getLibraryPages(libraryId, options) {
	const params = { 
		pn: options.pn || 1,
		ps: options.ps || 10,
		so_group: options.so_group || undefined,
		so_field: options.so_field || "page_views",
		so_direction: options.so_direction || "DESC",
		filter_expression: options.filter_expression || undefined,
		tags: options.tags || undefined
	}

	const res = await api.get(`/api/libraries/${libraryId}/pages`, { 
		params: {
			json: JSON.stringify(params)
		} 
	})

	if (res?.data?.results) {
		const count = res.data?.count || 0
		const pages = res.data.results.map(data => {
			data.libraryId = libraryId
			return new BasePage(data)
		})
		return { count, pages }
	} else {
		return
	}
}

