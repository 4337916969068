import { TimeSeries, emptyData } from "@/utils"
import { useLibraryStore } from "@/stores"
import { round } from "@/utils"
import { getPageSpeed, getPageMeta, getPageGsc, getPageReferrers, getPageAsReferrer } from "@/api/page"


export default class BasePage {
	constructor(page) {
		const libraryStore = useLibraryStore()
		const maxViewedPage = libraryStore.maxViewedPage || 10000
		this.id = page.id
		this.libraryId = page.libraryId
		this.hostname = page.hostname
		this.canonical_page_id = page.canonical_page_id
		this.best_title = page.best_title
		this.tags = page.tags

		this.meta = {
			title: page.best_title != null && page.best_title?.length > 0 ? page.best_title : page.canonical_page_id,
			url: page.canonical_page_id, 
			hostname: page.hostname,
			path: page.canonical_page_id.replace(page.hostname, ""),
			combinedSearchField: `${page.canonical_page_id} ${page.best_title}`.toLowerCase()
		}

		if (this.meta.path === "") {
			this.meta.path = "/"
		}

		this.timeSeries = {
			all: new TimeSeries(page.all_page_data || []),
			org: new TimeSeries(page.org_page_data || [])
		}

		this.org1Month = this.timeSeries.org.getData ? this.timeSeries.org.getData(1, "month") : emptyData
		this.org3Month = normalizePageData(page.org_last_3_months_data)
		this.org6Month = normalizePageData(page.org_last_6_months_data)
		this.org12Month = normalizePageData(page.org_last_12_months_data)

		const org3MonthComparison = this.org6Month.pageviews - this.org3Month.pageviews
		this.org3Month.growthPercent = org3MonthComparison != 0 ? (this.org3Month.pageviews - org3MonthComparison) / org3MonthComparison : 1

		if (page.metrics) {
			this.daysAlive = page.metrics?.days_alive
			this.calcs = page.metrics
		} else {
			this.calcs = {
				scale: 0,
				engagement: 0,
				positivity: 0,
				consistency: 0,
			}
		}

		this.calcs.score = getScore(this.calcs)

		this.collections = {
			isLemon: isTag(this, "Lemons"),
			isStar: isTag(this, "Stars"),
			isSloth: isTag(this, "Sloths"),
			isWallflower: isTag(this, "Wallflowers"),
			isDeclining: isTag(this, "Declining"),
			isNewGrowth: isTag(this, "New Growth"),
			isMissing: (this.meta.title.toLowerCase().includes("404") || this.meta.title.toLowerCase().includes("page not found")),
		}

		if (this.collections.isNewGrowth) {
			this.collections.isDeclining = false
			this.collections.isSloth = false
			this.collections.isStar = false
			this.collections.isWallflower = false
		}

		this.flags = {
			updateContent: this.collections.isDeclining,
			increaseEngagement: this.collections.isSloth,
			repurpose: this.collections.isLemon,
			titleLengthIncorrect: this.meta.title.length >= 70,
			titleIsMissing: this.meta.title === "(Not Set)",
		}

		if (this.flags.repurpose) {
			this.flags.titleIsMissing = false
			this.flags.titleLengthIncorrect = false
			this.flags.titleIsDuplicate = false
			this.flags.updateContent = false
			this.flags.increaseEngagement = false
		}

		this.calcs.opportunity = getOpportunity(this)
		this.calcs.opportunityGrowth = this.calcs.opportunity - this.calcs.score
		this.calcs.opportunityGrowthMetric = theoreticalGrowth(this, maxViewedPage)

		this.pageSpeedLink = "https://pagespeed.web.dev/report?url=" + encodeURIComponent(this.canonical_page_id)
	}

	async getReferrers() {
		if (!this.referrers) {
			this.referrers = await getPageReferrers(this.libraryId, this.canonical_page_id)
		}
		return this.referrers
	}

	async getReferrals() {
		if (!this.referrals) {
			this.referrals = await getPageAsReferrer(this.libraryId, this.canonical_page_id)
		}
		return this.referrals
	}

	async getSpeed(type = "MOBILE") {
		if (!this.speed){
			this.speed = {}
		}

		if (!this.speed[type.toLowerCase()]) {
			this.speed[type.toLowerCase()] = await getPageSpeed(this.canonical_page_id, type)
		} else {
			return this.speed
		}
	}

	async getMetaScrape() {
		if (!this.metaScrape) {
			this.metaScrape = await getPageMeta(this.canonical_page_id)
		}
		return this.metaScrape
	}

	async getGsc() {			
		if (!this.organic) {
			this.organic = {} 
			this.organic.keywords = await getPageGsc(this.libraryId, this.id)
			this.organic.clicks = this.organic.keywords.reduce((sum,cur) => sum + cur.clicks , 0)
			this.organic.impressions = this.organic.keywords.reduce((sum,cur) => sum + cur.impressions , 0)
		}
		return this.organic
	}
}


function isTag(page, tagName) {
	if (!page?.tags?.length) return false
	const found = page.tags.find(tag => tag.name === tagName)
	return !!found
}

function normalizePageData(obj) {
	if (!obj) {
		return emptyData
	} else {
		return {
			pageviews: obj.page_views || 0,
			bounces: obj.bounces || 0,
			sessions: obj.sessions || 0,
			bounceRate: obj.sessions === 0 ? 0 : obj.bounces / obj.sessions 
		}
	}
}

function theoreticalGrowth(page, maxViewedPage) {
	const scoreChange = page.calcs.opportunityGrowth
	if (scoreChange === 0) return 0
	const currentPage = page.org12Month.pageviews
	const averagePage = (maxViewedPage + currentPage) / 2
	const yearGrowth = (averagePage * scoreChange * 0.8) + (10000 * scoreChange * 0.2)
	const smallPageModifier = .5 + (Math.min(currentPage / 30, 1) * 0.5)
	// add a 'website size' modifier in here as well
	const modifiedGrowth = Math.max(Math.round(yearGrowth * smallPageModifier), 5)
	return Math.round(modifiedGrowth / 12)
}

function getOpportunity(page) {
	let newCalcs = { ...page.calcs }
	const viewThreshold = 4

	if (page.flags.titleIsDuplicate && page.org3Month.pageviews > viewThreshold) {
		newCalcs.scale = Math.min(page.calcs.scale + 0.05, page.calcs.scale * 1.1)
	}
	if (page.flags.titleLengthIncorrect && page.org3Month.pageviews > viewThreshold) {
		newCalcs.scale = Math.min(page.calcs.scale + 0.05, page.calcs.scale * 1.1)
	}
	if (page.flags.titleIsMissing && page.org3Month.pageviews > viewThreshold) {
		newCalcs.scale = Math.min(page.calcs.scale + 0.05, page.calcs.scale * 1.1)
	}
	if (page.flags.updateContent) {
		newCalcs.scale = Math.min(page.calcs.scale + 0.06, 1)
		newCalcs.positivity = Math.min(page.calcs.positivity + 0.05, 1)
		newCalcs.engagement = Math.min(page.calcs.engagement + 0.05, 1)
		newCalcs.consistency = Math.min(page.calcs.consistency + 0.05, 1)
	}
	if (page.flags.increaseEngagement) {
		newCalcs.engagement = Math.min(page.calcs.engagement + 0.15, 1)
	}
	if (page.flags.repurpose) {
		// theoretical slightly below average page on a site, should calculate this
		newCalcs.scale = Math.min(page.calcs.scale * 3, .11)
		newCalcs.consistency = Math.min(page.calcs.consistency * 3, .22)
		newCalcs.positivity = Math.min(page.calcs.positivity * 3, .16)
		newCalcs.engagement = Math.min(page.calcs.engagement * 3, .15)
	}
	if (page.calcs.score == getScore(newCalcs)) {
		return page.calcs.score
	} else {
		return Math.min(getScore(newCalcs) * (1.02 + page.calcs.scale * 0.07), 1)
	}
}

function getScore(calcs) {
	const scale = calcs.scale
	const positivity = calcs.positivity
	const consistency = calcs.consistency
	const engagement = calcs.engagement

	const scaleWeight = 0.22
	const positivityWeight = 0.29
	const consistencyWeight = 0.25
	const engagementWeight = 0.24

	const score = Math.min(1.06 *
      (scale * scaleWeight +
        positivity * positivityWeight +
        consistency * consistencyWeight +
        engagement * engagementWeight), 1)

	// theoretical best existing web page rank
	// const score = 1 * 0.33 + 0.9 * 0.24 + 0.9 * 0.21 + 0.98 * 0.22

	return round(score)
}
