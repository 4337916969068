import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_DocumentTableCellLabel = _resolveComponent("DocumentTableCellLabel");
  const _component_DocumentTableCell = _resolveComponent("DocumentTableCell");
  return _openBlock(), _createElementBlock("tr", null, [_createVNode(_component_DocumentTableCellLabel, null, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "label")]),
    _: 3
  }), _createVNode(_component_DocumentTableCell, {
    colspan: 11
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  })]);
}