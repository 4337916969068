import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["colspan"];
export default {
  __name: 'DocumentTableCellImage',
  props: {
    colspan: {
      type: Number,
      default: 11
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("td", {
        class: "border",
        colspan: __props.colspan
      }, [_renderSlot(_ctx.$slots, "default")], 8, _hoisted_1);
    };
  }
};