import { api } from "@/api"
import dayjs from "dayjs"
import { estimateKeywordVolume } from "@/utils"
import { useLibraryStore } from "@/stores"

export async function getLibraryGscWithRegex(libraryId, options) {
	const libraryStore = useLibraryStore()
	
	const body = {
		"startDate": options.startDate || dayjs().subtract(31, "day").format("YYYY-MM-DD"),
		"endDate": options.endDate || dayjs().subtract(1, "day").format("YYYY-MM-DD"),
		"rowLimit": options.rowLimit || 10,
		"dimensions": options.dimensions || "QUERY",
		"dimensionFilterGroups": [
			{
				"groupType": "AND",
				"filters": [
					{
						"dimension": options.regexDimension || "QUERY",
						"operator": options.regexType || "INCLUDING_REGEX",
						"expression": options.regex || "",
					},
				],
			},
		],
	}

	if (libraryStore.gscUrlFilters?.length > 0) {
		libraryStore.gscUrlFilters.forEach(filter => {
			body.dimensionFilterGroups[0].filters.push(filter)
		})
	}

	const res = await api.post(`api/libraries/${libraryId}/gsc`, body)
	if (res?.data?.rows?.length > 0) {
		return res.data.rows.map(row =>{
			row.volume = estimateKeywordVolume(row.position, row.impressions)
			return row
		})
	} else {
		return []
	}
}