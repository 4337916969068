import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 0
};
import { numDisplay } from "@/utils";
export default {
  __name: 'DocumentTableGroupOrganic',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_DocumentTableRowHeader = _resolveComponent("DocumentTableRowHeader");
      const _component_DocumentTableRow = _resolveComponent("DocumentTableRow");
      const _component_DocumentTableGroupKeywords = _resolveComponent("DocumentTableGroupKeywords");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_DocumentTableRowHeader, null, {
        default: _withCtx(() => [_createTextVNode("Organic Search – 30 Day")]),
        _: 1
      }), _createVNode(_component_DocumentTableRow, null, {
        label: _withCtx(() => [_createTextVNode(" Clicks ")]),
        default: _withCtx(() => [__props.options?.page?.organic ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(numDisplay)(__props.options.page.organic.clicks)), 1)) : _createCommentVNode("", true)]),
        _: 1
      }), _createVNode(_component_DocumentTableRow, null, {
        label: _withCtx(() => [_createTextVNode(" Impressions ")]),
        default: _withCtx(() => [__props.options?.page?.organic ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(numDisplay)(__props.options.page.organic.impressions)), 1)) : _createCommentVNode("", true)]),
        _: 1
      }), __props.options?.page?.organic?.keywords ? (_openBlock(), _createBlock(_component_DocumentTableGroupKeywords, {
        key: 0,
        options: __props.options,
        keywords: __props.options.page.organic.keywords
      }, null, 8, ["options", "keywords"])) : _createCommentVNode("", true)], 64);
    };
  }
};