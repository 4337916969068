import { api } from "@/api"

export async function postGoogleDriveFile(workspaceId, fileBody, title = "ércule app document") {
	const body = { 
		"title": title,
		"media_mimetype": "text/html",
		"upload_mimetype": "application/vnd.google-apps.document",
		"content": fileBody
	}
	return await api.post(`/api/workspaces/${workspaceId}/google-drive-files`, body)
}
