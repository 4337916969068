import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "leading-5 border p-[8px] w-[125px] font-bold",
  colspan: "1"
};
const _hoisted_2 = {
  style: {
    "font-size": "0.75em"
  }
};
export function render(_ctx, _cache) {
  return _openBlock(), _createElementBlock("td", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")])]);
}