import escapeStringRegexp from "escape-string-regexp"


export function urlToCanonicalPageId(url) {
	if (typeof url !== "string") return ""

	let urlString = url.replace("https://", "").replace("http://", "")

	if (urlString.slice(-1) === "/") {
		urlString = urlString.slice(0, -1)
	}

	return urlString
}


export function buildGscUrl(pageUrl, siteUrl, userEmail) {
	if (!pageUrl) return undefined

	const regex = "https?://" + escapeStringRegexp(pageUrl) + "/?$"
	const baseUrl = "https://search.google.com/search-console/performance/search-analytics"
	const userParam = `authuser=${userEmail}`
	const resourceParam = `resource_id=${encodeURIComponent(siteUrl)}`
	const pageParam = `page=~${encodeURIComponent(regex)}`
	return `${baseUrl}?${userParam}&${resourceParam}&${pageParam}&breakdown=query&num_of_days=28&compare_date=PREV`
}


export function buildGaUrl(page, propertyId, userEmail = "0") {
	if (!page?.meta || !propertyId) return undefined
		
	const hostname = page.meta.hostname
	const path = page.meta.path
	const regex = escapeStringRegexp(path) + "/?$"

	const comparisons = [{
		"name":"All",
		"isEnabled":true,
		"filters":[]
	},{
		"name":"Organic Users",
		"isEnabled":true,
		"filters":[{
			"fieldName":"sessionDefaultChannelGrouping",
			"evaluationType":3,
			"expressionList":["Organic Search"],
			"isCaseSensitive":false
		}]
	}]

	const dataFilters = [{
		"type":1,
		"fieldName":"unifiedPagePathScreen",
		"evaluationType":2,
		"expressionList":[regex],
		"complement":false,
		"isCaseSensitive":false,
		"expression":""
	},{
		"type":1,
		"fieldName":"hostname",
		"evaluationType":1,
		"expressionList":[hostname],
		"complement":false,
		"isCaseSensitive":false,
		"expression":""
	}]

	const cardParams = "_u..nav=maui&_r.explorerCard..selmet=[\"screenPageViews\"]&_r.explorerCard..seldim=[\"unifiedPagePathScreen\"]&_r.explorerCard..startRow=0"
	const compParams = `_u..comparisons=${JSON.stringify(comparisons)}&_u..built_comparisons_enabled=true`
	const dataParams = `&_r..dataFilters=${JSON.stringify(dataFilters)}`
	const allParams = `${cardParams}&${compParams}&${dataParams}`

	const baseUrl = "https://analytics.google.com/analytics/web/?"
	const userString = `authuser=${ userEmail }`
	const viewString = `#/p${ propertyId }/reports/explorer?params=${encodeURIComponent(allParams)}&r=all-pages-and-screens`

	return baseUrl + userString + viewString
}

