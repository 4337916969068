import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export default {
  __name: 'DocumentSeoUpdate',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_DocumentTableGroupStandard = _resolveComponent("DocumentTableGroupStandard");
      const _component_DocumentTableGroupOrganic = _resolveComponent("DocumentTableGroupOrganic");
      const _component_DocumentTableGroupTechnical = _resolveComponent("DocumentTableGroupTechnical");
      const _component_DocumentTableGroupMeta = _resolveComponent("DocumentTableGroupMeta");
      const _component_DocumentTableBase = _resolveComponent("DocumentTableBase");
      return _openBlock(), _createBlock(_component_DocumentTableBase, null, {
        default: _withCtx(() => [_createVNode(_component_DocumentTableGroupStandard, {
          options: __props.options
        }, {
          default: _withCtx(() => [_createTextVNode(" ⚒️ SEO Update ")]),
          _: 1
        }, 8, ["options"]), __props.options?.page?.organic ? (_openBlock(), _createBlock(_component_DocumentTableGroupOrganic, {
          key: 0,
          options: __props.options
        }, null, 8, ["options"])) : _createCommentVNode("", true), __props.options?.page?.mobileSpeed ? (_openBlock(), _createBlock(_component_DocumentTableGroupTechnical, {
          key: 1,
          options: __props.options
        }, null, 8, ["options"])) : _createCommentVNode("", true), _createVNode(_component_DocumentTableGroupMeta, {
          options: __props.options
        }, null, 8, ["options"])]),
        _: 1
      });
    };
  }
};