import { api } from "@/api"
import BasePage from "@/utils/BasePage.js"

export async function getTag(libraryId, tagId) {
	const res = await api.get(`/api/libraries/${libraryId}/tags/${tagId}/`)

	if (res?.data) {
		const tag = res.data
		if (tag.pages?.length > 0) {
			tag.pages = tag.pages.map(page => new BasePage(page))
		}
		return tag
	} else {
		return
	}
}
