import dayjs from "dayjs"
import { getCompoundGrowth } from "@/utils"
import { useLibraryStore } from "@/stores"

export class TimeSeries {
	constructor(rawData = [], type = "page") {
		this.earliestDate = dayjs()
		this.latestDate = dayjs().year(1900)
		this.data = []
		this.searchableData = {}

		rawData.forEach(item => {
			if (type === "page") {
				const date = dayjs(`${item.year}-${item.month}-01`)
				item.start_date = date.startOf("month")
				item.end_date = date.endOf("month")
			} else if (type === "aggregate") {
				item.start_date = dayjs(item.start_date).startOf("day")
				item.end_date = dayjs(item.end_date).endOf("day")
			}
			item.data = formatData(item)
			item.key = getSearchKey(item.start_date, item.end_date)
			item.type = getDataType(item)

			this.earliestDate = item.start_date.valueOf() < this.earliestDate.valueOf() ? item.start_date : this.earliestDate
			this.latestDate = item.end_date.valueOf() > this.latestDate.valueOf() ? item.end_date : this.latestDate

			this.addTimePeriod(item)
		})

		this.earliestDateDisplay = this.earliestDate.format("MMM YYYY")
	}

	addTimePeriod(item) {
		if (!item.data) {
			item.days = this.data.filter(d => {
				return d.type === "day" 
					&& d.start_date.valueOf() >= item.start_date.valueOf() 
					&& d.start_date.valueOf() <= item.end_date.valueOf() 
			})
			item.data = flattenData(item.days)
		}

		this.data.push(item)
		this.searchableData[item.key] = this.data.length - 1
		return item
	}

	getTimeSequence(length, type, skip = 0) {
		const sequence = []
		const libraryStore = useLibraryStore()
		const syncDate = libraryStore?.status?.syncDate || dayjs().subtract(3, "day")
		let iterator = !length ? this.earliestDate.startOf(type) : syncDate.subtract(skip, type).subtract(length, type).startOf("day")
		const endPoint = syncDate.subtract(skip, type).endOf("day")

		while (iterator.valueOf() < endPoint.valueOf()) {
			let item = {
				type,
				start_date: iterator.startOf(type),
				end_date: iterator.endOf(type),
				key: getSearchKey(iterator.startOf(type), iterator.endOf(type))
			}

			const exactIndex = this.searchableData[item.key]
			const period = exactIndex ? this.data[exactIndex] : this.addTimePeriod(item)
			sequence.push(period)
			iterator = iterator.add(1, type)
		}

		return sequence
	}

	getData(length, type) {
		const data = this.getTimeSequence(length, type)
		const rollup = basicRollup(data)
		const compare = this.getTimeSequence(length, type, length)
		const compareRollup = basicRollup(compare)

		rollup.previous = compareRollup
		rollup.bounceRate = rollup.bounces / rollup.sessions || 0

		if (compareRollup?.pageviews > 0) {
			rollup.growthPercent = (rollup.pageviews - compareRollup.pageviews) / compareRollup.pageviews
			rollup.growthMetric = rollup.growthPercent * rollup.pageviews
		}
		return rollup
	}

	getGraphData(length, type, datapoint, projected = false) {
		const libraryStore = useLibraryStore()
		const syncDate = libraryStore.status?.syncDate
		const format = type === "week" || type === "day" ? "M/D" : "MMM YY"
		const sequence = this.getTimeSequence(length, type)
		const labels = sequence.map(period => period.start_date.format(format))

		const data = sequence.map((period,index) => {
			if (!projected) {
				return period.data ? period.data[datapoint] : undefined
			}

			// don't try to project data for individual days, or when we don't have a syncDate
			if (!syncDate || period.type === "day") return undefined

			// only project data for the period that falls on our current syncDate
			const isCurrent = syncDate.valueOf() <= period.end_date.valueOf() && syncDate.valueOf() >= period.start_date.valueOf()
			if (!isCurrent) return undefined

			const previousPeriod = sequence[index - 1]
			const dayLength = Math.abs(period.start_date.diff(period.end_date, "day")) + 1
			const dayActual = Math.abs(period.start_date.diff(syncDate, "day")) + 1
			const percentFinished = Math.min(dayActual / dayLength, 1)
			const dataProjected = Math.round((period.data[datapoint] / dayActual) * dayLength)
			let growthProjected

			if (previousPeriod?.data && previousPeriod?.data[datapoint]) {
				const oneYearRollup = basicRollup(sequence.slice(-12))
				growthProjected = Math.round(Math.max(previousPeriod.data[datapoint] + (previousPeriod.data[datapoint] * oneYearRollup.cgr), 0))
			}

			// growthProjected is what we guess the partial period will be based on the run of previous periods
			// dataProjected is what we guess the partial period will be based on where it's currently tracking, agnostic of other periods
			if (growthProjected && dataProjected) {
				// if we have both sources, we incrementally prefer dataProjected as the period gets closer to finishing
				const growthProjectedModified = growthProjected * (1 - percentFinished) 
				const dataProjectedModified = dataProjected * percentFinished
				return Math.round(growthProjectedModified + dataProjectedModified)
			} else {
				return dataProjected || growthProjected || undefined
			}
		})

		return { data, labels }
	}
}


export const emptyData = {
	sessions: 0,
	pageviews: 0,
	bounces: 0,
	bounceRate: 0
}

function getSearchKey (start, end) {
	return start.format("YYYY-MM-DD") + end.format("YYYY-MM-DD")
}

function formatData(data) {
	const dataObj = {
		pageviews: data?.page_views || 0,
		bounces: data?.bounces || 0,
		sessions: data?.sessions || 0,
	}
	dataObj.bounceRate = dataObj.sessions != 0 ? dataObj.bounces / dataObj.sessions : 0
	return dataObj
}

function flattenData(items) {
	const data = {
		sessions: items.reduce((sum, item) => sum + item?.data?.sessions, 0),
		pageviews: items.reduce((sum, item) => sum + item?.data?.pageviews, 0),
		bounces: items.reduce((sum, item) => sum + item?.data?.bounces, 0),
	}
	data.bounceRate = data.sessions != 0 ? data.bounces / data.sessions : 0
	return data
}

function basicRollup(items) {
	if (!items?.length > 0) return emptyData
	
	const base = flattenData(items) 
	base.start_date = items[0].start_date
	base.end_date = items[items.length - 1].end_date
	base.cgr = getCompoundGrowth(items.map(period => period?.data?.pageviews || 0 ))
	base.avg = {
		bounces: Math.round(base.bounces / items.length),
		sessions: Math.round(base.sessions / items.length),
		pageviews: Math.round(base.pageviews / items.length),
	}
	return base
}

function getDataType(item) {
	if (item.start_date.date() === 1 && item.start_date.month() === item.end_date.month() && item.end_date.date() === item.start_date.endOf("month").date()) {
		return "month"
	} else if (item.start_date.format("YYYY-MM-DD") === item.end_date.format("YYYY-MM-DD")) {
		return "day"
	} else {
		return undefined
	}
}
