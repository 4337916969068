import { api } from "@/api"
import BasePage from "@/utils/BasePage.js"

export async function getPage(libraryId, pageId) {
	const res = await api.get(`/api/libraries/${libraryId}/pages/${pageId}`)
	if (res?.data) {
		res.data.libraryId = libraryId
		return new BasePage(res.data)
	} else {
		return 
	}
}