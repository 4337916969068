import { api } from "@/api"
import { urlToCanonicalPageId } from "@/utils"

export async function getPageAsReferrer(libraryId, pageUrl) {
	const body = {
		"dimensions": [
			{ "name": "fullPageUrl" },
			{ "name": "pageReferrer" }
		],
		"metrics": [
			{ "name": "sessions" }
		],
		"dateRanges": [{
			"startDate": "30daysAgo",
			"endDate": "yesterday"
		}],
		"dimensionFilter": {
			"filter": {
				"fieldName": "pageReferrer",
				"inListFilter": {
					"values": [
						`https://${pageUrl}/`,
						`https://${pageUrl}`,
						`http://${pageUrl}/`,
						`http://${pageUrl}`,
					]
				}
			}
		},
		"limit": "50",
		"orderBys": [{
			"metric": { "metricName": "sessions" },
			"desc": true
		}]
	}
	const res = await api.post(`api/libraries/${libraryId}/ga4-open`, body)

	if (res?.data) {
		if (res.data.rows?.length > 0) {
			return res.data.rows.map(row => {
				return {
					url: urlToCanonicalPageId(row.dimensionValues[0].value),
					sessions: parseInt(row.metricValues[0].value),
				}
			})
		} else {
			return []
		}
	} else {
		return
	}
}