import { useModel as _useModel } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, isRef as _isRef, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "flex gap-x-2"
};
const _hoisted_2 = {
  class: "w-full flex justify-end"
};
import { useRouteQuery } from "@vueuse/router";

import { useLibraryStore, useLocalStore } from '@/stores';
import { onMounted, ref } from 'vue';
export default {
  __name: 'ActionItemsTable',
  props: {
    "conf": {
      type: Object,
      required: true
    },
    "confModifiers": {}
  },
  emits: ["update:conf"],
  setup(__props) {
    const libraryStore = useLibraryStore();
    const {
      pageTableSettings: settings,
      tableSettings
    } = useLocalStore();
    const conf = _useModel(__props, "conf");
    onMounted(async () => {
      await libraryStore.getAllPages();
      conf.value.get();
    });
    const monthRange = useRouteQuery("month_range", 3, {
      transform: Number
    });
    const monthRangeOptions = ref([{
      title: "3 Month",
      value: 3
    }, {
      title: "6 Month",
      value: 6
    }, {
      title: "12 Month",
      value: 12
    }]);
    return (_ctx, _cache) => {
      const _component_TableSearch = _resolveComponent("TableSearch");
      const _component_PageTableNavResetFilters = _resolveComponent("PageTableNavResetFilters");
      const _component_BaseTabs = _resolveComponent("BaseTabs");
      const _component_TableNavCopy = _resolveComponent("TableNavCopy");
      const _component_PageTableMenu = _resolveComponent("PageTableMenu");
      const _component_PageColumnTitleOrUrl = _resolveComponent("PageColumnTitleOrUrl");
      const _component_PageColumnRecommendations = _resolveComponent("PageColumnRecommendations");
      const _component_hero_arrow_right_16_solid = _resolveComponent("hero-arrow-right-16-solid");
      const _component_PageColumnViews = _resolveComponent("PageColumnViews");
      const _component_PageColumnViewGrowth = _resolveComponent("PageColumnViewGrowth");
      const _component_TablePagination = _resolveComponent("TablePagination");
      const _component_TableBase = _resolveComponent("TableBase");
      return _openBlock(), _createBlock(_component_TableBase, {
        conf: conf.value,
        "onUpdate:conf": _cache[2] || (_cache[2] = $event => conf.value = $event),
        "link-to": "PageSingle",
        class: _normalizeClass(["min-w-[950px]", {
          'slim': _unref(tableSettings).compact
        }])
      }, {
        pre: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_TableSearch, {
          class: "max-w-sm xl:max-w-md"
        }), _createVNode(_component_PageTableNavResetFilters), _createVNode(_component_BaseTabs, {
          tabs: _unref(monthRangeOptions),
          "onUpdate:tabs": _cache[0] || (_cache[0] = $event => _isRef(monthRangeOptions) ? monthRangeOptions.value = $event : null),
          selected: _unref(monthRange),
          "onUpdate:selected": _cache[1] || (_cache[1] = $event => _isRef(monthRange) ? monthRange.value = $event : null),
          class: "ml-auto mr-8"
        }, null, 8, ["tabs", "selected"]), _createVNode(_component_TableNavCopy, {
          items: conf.value.getCheckedItems()
        }, null, 8, ["items"]), _createVNode(_component_PageTableMenu)])]),
        columns: _withCtx(() => [_createVNode(_component_PageColumnTitleOrUrl, {
          "title-url-toggle": _unref(settings).titleUrlToggle,
          class: "w-full"
        }, null, 8, ["title-url-toggle"]), _createVNode(_component_PageColumnRecommendations, {
          class: "w-[325px]"
        }), _createVNode(_component_PageColumnViews, {
          "month-range": _unref(monthRange),
          class: "w-[125px]"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_hero_arrow_right_16_solid, {
            class: "w-4 h-4 mr-4 text-green-500"
          })])]),
          _: 1
        }, 8, ["month-range"]), _createVNode(_component_PageColumnViewGrowth, {
          "month-range": _unref(monthRange),
          class: "w-[135px]"
        }, null, 8, ["month-range"])]),
        footer: _withCtx(() => [_createVNode(_component_TablePagination)]),
        _: 1
      }, 8, ["conf", "class"]);
    };
  }
};