import { h, createApp } from "vue"
import juice from "juice"
import TurndownService from "turndown"
import showdown from "showdown"


export async function vueComponentToHTML(component, options = {}) {
	const dryDoc = document.implementation.createHTMLDocument("ercule document")

	const vueAppDoc = document.implementation.createHTMLDocument("Document")
	const tempApp = createApp({
		render() {
			return h(component, { options: options })
		}
	})
	tempApp.mount(vueAppDoc.body)
	const vueAppDiv = await addCSSAndJuice(vueAppDoc, "/tailwind-output.css")
	dryDoc.querySelector("body").appendChild(vueAppDiv)

	if (options.pageBody) {
		const pageDoc = document.implementation.createHTMLDocument("Document")
		pageDoc.body.innerHTML = cleanHtml(options.pageBody)
		const pageDocDiv = await addCSSAndJuice(pageDoc, "/scraped-page-content.css")
		dryDoc.querySelector("body").appendChild(pageDocDiv)
	}
	return dryDoc.documentElement.outerHTML
}


async function addCSSAndJuice(doc, style) {
	const styleEl = document.createElement("style")
	const cssFile = await fetch(style)
	styleEl.textContent = await cssFile.text()
	doc.head.appendChild(styleEl)
	// encoding fix below, should be implemented in backend instead
	// html = html.replaceAll('↓', 'v').replaceAll('↑', '^').replaceAll('×', 'x')
	doc.documentElement.innerHTML = juice(doc.documentElement.innerHTML)
	const div = document.createElement("div")
	div.innerHTML = doc.body.innerHTML
	return div
}


function cleanHtml(content) {
	const turndownService = new TurndownService()
	const markdown = turndownService.turndown(content)
	const converter = new showdown.Converter()
	return converter.makeHtml(markdown)
}