import { useModel as _useModel } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { buildGscUrl } from "@/utils";

import { useLibraryStore, useUserStore } from '@/stores';
export default {
  __name: 'PageSingleModuleKeywords',
  props: {
    "page": {
      type: Object,
      required: true
    },
    "pageModifiers": {}
  },
  emits: ["update:page"],
  setup(__props) {
    const libraryStore = useLibraryStore();
    const userStore = useUserStore();
    const page = _useModel(__props, "page");
    const gscLink = buildGscUrl(page.value.canonical_page_id, libraryStore.gscSiteurl, userStore.user.email);
    async function getKeywords() {
      const organic = await page.value.getGsc();
      return organic?.keywords;
    }
    return (_ctx, _cache) => {
      const _component_KeywordsBaseTable = _resolveComponent("KeywordsBaseTable");
      return _openBlock(), _createBlock(_component_KeywordsBaseTable, {
        id: "page-keyword-table",
        get: getKeywords,
        "gsc-link": _unref(gscLink),
        class: "legacy-table slim max-w-4xl mx-auto my-10"
      }, null, 8, ["gsc-link"]);
    };
  }
};