import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["colspan"];
const _hoisted_2 = {
  style: {
    "font-size": "9pt"
  }
};
export default {
  __name: 'DocumentTableCell',
  props: {
    colspan: {
      type: Number,
      default: 11
    }
  },
  setup(__props) {
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("td", {
        class: "leading-5 border p-[8px]",
        colspan: __props.colspan
      }, [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "default")])], 8, _hoisted_1);
    };
  }
};