import { getLibraryPages, getLibraryGscWithRegex, getLibraryStatus } from "@/api/library";
import { listTags } from "@/api/tags";
import { mapLibraryStatus, urlToCanonicalPageId, getPageMatchScore } from "@/utils";
export const useLibraryStore = defineStore("library", () => {
  const notificationStore = useNotificationStore();
  const userStore = useUserStore();
  const libraryInit = {
    id: null,
    name: null,
    status: null,
    filters: null,
    ga4PropertyId: null,
    uaPropertyId: null,
    gscSiteurl: null,
    hostnameFilterType: null,
    pathFilterType: null,
    hostnameFilterValues: null,
    pathFilterValues: null,
    pageCount: null,
    pages: [],
    maxViewedPage: null,
    tags: null,
    workspaceId: null,
    workspacePlanType: null
  };
  const library = reactive({
    ...libraryInit
  });
  function resetLibrary() {
    Object.assign(library, libraryInit);
  }
  async function loadLibrary(workspaceId, libraryId) {
    resetLibrary();
    if (!libraryId || !workspaceId) return;
    library.id = libraryId;
    library.workspaceId = workspaceId;
    userStore.klaviyoCall("Loaded Library", {
      library_id: libraryId
    });
    await getStatus();
    library.tags = await listTags(library.id);
  }
  async function getStatus() {
    const pollingTime = 4000;
    const data = await getLibraryStatus(library.workspaceId, library.id);
    if (!data) {
      return setTimeout(getStatus, pollingTime);
    } else {
      updateLibrary(data);
      return library.status?.tier !== 0 ? setTimeout(getStatus, pollingTime) : undefined;
    }
  }
  function updateLibrary(data) {
    Object.assign(library, data);
    document.title = `ércule – ${library.name} library`;
    const currentStatus = library.status;
    const newStatus = mapLibraryStatus(data);
    if (!currentStatus && newStatus.tier !== 0) {
      notificationStore.setNotification("Library data currently syncing...", "This may take a few minutes while we intelligently roll up and process all your data.", "blue");
    } else if (currentStatus && currentStatus?.tier !== newStatus?.tier && newStatus?.tier === 0) {
      notificationStore.setNotification("Library sync complete!", "We've finished your library analysis. Take a look in any of our 'collections' to see what we found.", "green");
    }
    library.status = newStatus || currentStatus;
  }
  async function getAllPages() {
    const currentID = library.id;
    if (library.pagesSyncing) {
      return await library.pagesPromise;
    } else if (library.pages && library.pages.length > 0) {
      return;
    } else {
      library.pagesSyncing = true;
      library.pagesPromise = getLibraryPages(library.id, {
        ps: 5000,
        pn: 1,
        so_group: "org_last_3_months_data",
        so_field: "page_views"
      });
      const res = await library.pagesPromise;
      library.pagesSyncing = false;
      if (library.id !== currentID) return;
      library.pages = res?.pages ? res.pages.filter(page => !page.collections.isMissing) : [];
    }
  }
  function getMatchingPageByUrl(pageUrl) {
    if (!pageUrl) return undefined;
    const matchUrl = urlToCanonicalPageId(pageUrl);
    return library.pages.find(libPage => matchUrl?.toLowerCase() === libPage?.canonical_page_id?.toLowerCase());
  }
  async function getTopicPages(topicObj, topicName) {
    let gscPageData = [];
    await getAllPages();
    if (library.gscSiteurl) {
      gscPageData = await getLibraryGscWithRegex(library.id, {
        rowLimit: 100,
        dimensions: ["PAGE"],
        regex: topicObj.regex
      });
    }
    const pagesWithSearchData = library.pages.map(page => {
      const emptyData = {
        topic: topicName || topicObj.string,
        clicks: 0,
        impressions: 0,
        keywordCount: 0
      };
      const gscData = gscPageData.find(row => page.canonical_page_id === urlToCanonicalPageId(row.keys[0]));
      page.searchData = Object.assign(emptyData, gscData);
      return page;
    });
    return pagesWithSearchData.filter(page => {
      return page.meta.combinedSearchField.match(topicObj.regex) || page.searchData.impressions > 0;
    }).map(page => {
      page.matchScore = getPageMatchScore(page, topicObj);
      return page;
    }).filter(page => page.matchScore > 50);
  }
  return {
    ...toRefs(library),
    loadLibrary,
    resetLibrary,
    getAllPages,
    getTopicPages,
    getMatchingPageByUrl
  };
});