import { api } from "@/api"
import dayjs from "dayjs"
import { estimateKeywordVolume } from "@/utils"
import { useLibraryStore } from "@/stores"

export async function getLibraryGscQueryAndPage(libraryId, options) {
	const libraryStore = useLibraryStore()

	const body = {
		"startDate": options.startDate || dayjs().subtract(31, "day").format("YYYY-MM-DD"),
		"endDate": options.endDate || dayjs().subtract(1, "day").format("YYYY-MM-DD"),
		"rowLimit": options.rowLimit || 10,
		"dimensions": ["QUERY","PAGE"],
		"dimensionFilterGroups": [
			{
				"groupType": "AND",
				"filters": [
					{
						"dimension": options.regexDimension || "QUERY",
						"operator": options.regexType || "INCLUDING_REGEX",
						"expression": options.regex,
					},
				],
			},
		],
	}

	if (libraryStore.gscUrlFilters?.length > 0) {
		libraryStore.gscUrlFilters.forEach(filter => {
			body.dimensionFilterGroups[0].filters.push(filter)
		})
	}

	const res = await api.post(`api/libraries/${libraryId}/gsc`, body)

	if (res?.data?.rows?.length > 0) {

		const keywordLibrary = {}

		res.data.rows.forEach(row=> {
			const keyword = row.keys[0]
			const url = row.keys[1]
			const impressions = row.impressions
			const clicks = row.clicks
			const position = row.position
			const thisPage = { url, clicks, impressions, position }

			if (keywordLibrary[keyword]) {
				keywordLibrary[keyword].clicks += row.clicks
				keywordLibrary[keyword].impressions += row.impressions
				keywordLibrary[keyword].pages.push(thisPage)
			} else {
				keywordLibrary[keyword] = { keyword, clicks, impressions, position, pages: [thisPage] }
			}
		})

		return Object.keys(keywordLibrary).map(key => {
			const keyword = keywordLibrary[key]
			// keyword.gscPosition = keyword.position
			// keyword.gscImpressions = keyword.impressions

			keyword.pages.sort((a,b) => a.position < b.position ? -1 : 1 )
			keyword.topPositionPage = keyword.pages[0]
			keyword.pages.sort((a,b) => a.impressions > b.impressions ? -1 : 1 )
			keyword.topImpressionPage = keyword.pages[0]
			// keyword.position = keyword.topPositionPage.position
			// keyword.impressions = keyword.topImpressionPage.impressions

			keyword.volume = estimateKeywordVolume(keyword.topImpressionPage.position, keyword.topImpressionPage.impressions)
			keyword.competition = 0

			// weighting method
			const totalImpressions = keyword.pages.reduce((sum, cur) => sum + cur.impressions, 0)
			const weightedImpressions = keyword.pages.reduce((sum, cur) => sum + (cur.impressions * cur.position), 0)
			keyword.weightedPosition = weightedImpressions / totalImpressions

			// scaling method
			keyword.pages.forEach(page => page.scale = page.impressions * (100 / page.position))
			keyword.pages.forEach(page => page.clickScale = page.clicks * (100 / page.position))
			keyword.pages.sort((a,b) => {
				return a.scale > b.scale ? -1 : 1 

				// if (a.clickScale === b.clickScale) {
				// 	return a.scale > b.scale ? -1 : 1 
				// } else {
				// 	return a.clickScale > b.clickScale ? -1 : 1 
				// }
			})
			keyword.position = keyword.pages[0].position
			keyword.impressions = keyword.pages[0].impressions

			// alternate scaling method
			// keyword.pages.forEach(page => page.scale2 = ((page.impressions / keyword.topImpressionPage.impressions) * 0.4) + (0.6 * (1 - (page.position / 50))) )
			// keyword.pages.sort((a,b) => {
			// 	return a.scale2 > b.scale2 ? -1 : 1 
			// })
			// keyword.positionScaled2 = keyword.pages[0].position
			// keyword.impressionsScaled2 = keyword.pages[0].impressions

			keyword.position = keyword.weightedPosition < keyword.position ? keyword.weightedPosition : keyword.position
			if (keyword.pages.length > 1) {
				keyword.position = Math.max(keyword.position - 0.4, 1)
			}
			keyword.positionRounded = Math.floor(keyword.position)

			return keyword
		})
			.sort((a,b)=> a.impressions > b.impressions ? -1 : 1)
			.sort((a,b)=> a.clicks > b.clicks ? -1 : 1)

	} else {
		return []
	}
}