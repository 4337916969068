export function round(num, precision = 2) {
	const factor = Math.pow(10, precision)
	return Math.round(num * factor) / factor
}


// type = "default" – just add commas i.e. 32,447
// type = "s" – abbreviate without any decimals i.e. 32K
// type = "m" – abbreviate with 1 decimals i.e. 32.4K
// type = "l" – abbreviate with 2 decimals i.e. 32.44K
// type = "%s" – abbreviate percent without any decimals i.e. 32%
// type = "%m" – abbreviate percent with 1 decimals i.e. 32.4%
// type = "%l" – abbreviate percent with 2 decimals i.e. 32.44%
export function numDisplay(num, type = "default") {
	const options = { maximumSignificantDigits: 3, roundingPriority: "lessPrecision" }

	if (typeof num === "number") {
		if (type === "s" || type === "%s") {
			options.maximumFractionDigits = 0
		} else if (type === "m" || type === "%m") {
			options.maximumFractionDigits = 1
		} else if (type === "l" || type === "%l") {
			options.maximumFractionDigits = 2
		}

		if (type === "s" || type === "m" || type === "l") {
			options.notation = "compact"
			options.compactDisplay = "short"
		} else if (type === "%s" || type === "%m" || type === "%l") {
			options.style = "percent"
		}

		return new Intl.NumberFormat("en-US", options).format(num)
	} else if (type === "%s" || type === "%m" || type === "%l") {
		return num + "%"
	} else {
		return num
	}
}


export function getCompoundGrowth(data) {
	let a, b, c, d, e, slope, yIntercept
	let dpsLength, xSum = 0, ySum = 0, xySum = 0, xSquare = 0
	const firstNonZero = data.findIndex(el => el > 0)

	if (firstNonZero < 0) return 0
	
	data = data.slice(Math.max(firstNonZero, 0))
	dpsLength = data.length

	for (let i = 0; i < dpsLength; i++) {
		xySum += (data[i] * i)
	}

	a = xySum * dpsLength

	for (let i = 0; i < dpsLength; i++){  	
		xSum += i
		ySum += data[i]
	}

	b = xSum * ySum

	for (let i = 0; i < dpsLength; i++) {
		xSquare += Math.pow(i, 2)    
	}

	c = dpsLength * xSquare
	d = Math.pow(xSum, 2)
	const div = c - d === 0 ? 0.01 : c - d
	slope = (a - b) / (div)  

	e = slope * xSum
	yIntercept = (ySum - e) / dpsLength

	// use y = mx + b (y = slope * x + yIntercept) here to graph individual points
	// slope / yIntercept returns base level growth percent
	// zeroing the yIntercept so when we calculate CMGR we don't work with negative numbers, this probably gives false high percentages for nearly empty datasets
	let start = (slope * 0) + Math.max(yIntercept, 0.01)
	let end = (slope * (dpsLength - 1)) + Math.max(yIntercept, 0.01)
	end = Math.max(end, 0)

	// formula for CMGR
	return (end / start) ** (1 / dpsLength) - 1
}