import Fuse from "fuse.js"

export function getPageMatchScore(page, topic) {
	const impressions = page.searchData?.impressions || 0
	const searchIn = page.meta.combinedSearchField
	const searchFor = topic.terms.map(term => term.trim().toLowerCase())

	let matchScore = 0
	for (const term of searchFor) {
		matchScore = Math.max(getFuzzyMatchScore(searchIn, term), matchScore)
	}
	const impressionsScore = impressions === 0 ? 0 : Math.min(impressions + 50, 200) / 2
	const blendedScore = (matchScore * 0.7) + (impressionsScore * 0.3)
	return Math.round(Math.max(matchScore, impressionsScore * 0.9, blendedScore))
}

export function getFuzzyMatchScore(searchIn, searchFor) {
	const fuseOptions = {
		includeScore: true,
		minMatchCharLength: 3,
		ignoreLocation: true
	}
	const fuseMatcher = new Fuse([searchIn], fuseOptions)
	const result = fuseMatcher.search(searchFor)
	return result.length > 0 ? 100 - Math.round(100 * result[0].score) : 0 
}