import { useModel as _useModel } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, isRef as _isRef, createBlock as _createBlock } from "vue";

import { ref, onMounted } from 'vue';
import { LocalTableConfig } from '@/utils';
const _hoisted_1 = {
  class: "text-lg leading-6 font-medium text-gray-900 mr-auto"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1,
  class: "truncate"
};
export default {
  __name: 'PageSingleTableReferrers',
  props: {
    "page": {
      type: Object,
      required: true
    },
    "pageModifiers": {}
  },
  emits: ["update:page"],
  setup(__props) {
    const page = _useModel(__props, "page");
    const conf = ref(new LocalTableConfig({
      key: "url",
      by: "sessions",
      pageSize: 5,
      getAsync: async () => {
        return await page.value.getReferrers();
      }
    }));
    onMounted(() => {
      conf.value.get();
    });
    return (_ctx, _cache) => {
      const _component_BaseBadgeInternal = _resolveComponent("BaseBadgeInternal");
      const _component_TableColumn = _resolveComponent("TableColumn");
      const _component_TablePagination = _resolveComponent("TablePagination");
      const _component_TableBase = _resolveComponent("TableBase");
      return _openBlock(), _createBlock(_component_TableBase, {
        conf: _unref(conf),
        "onUpdate:conf": _cache[0] || (_cache[0] = $event => _isRef(conf) ? conf.value = $event : null)
      }, {
        title: _withCtx(() => [_createElementVNode("h3", _hoisted_1, [_createTextVNode(" Referring Pages – 30 Days "), _createVNode(_component_BaseBadgeInternal)])]),
        columns: _withCtx(({
          item
        }) => [_createVNode(_component_TableColumn, {
          class: "w-full",
          "sort-by": "url",
          title: item.url
        }, {
          title: _withCtx(() => [_createTextVNode(" URL ")]),
          default: _withCtx(() => [!item.url || item.url === '' ? (_openBlock(), _createElementBlock("span", _hoisted_2, "(Unknown)")) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(item.url), 1))]),
          _: 2
        }, 1032, ["title"]), _createVNode(_component_TableColumn, {
          class: "w-[160px]",
          "sort-by": "sessions"
        }, {
          title: _withCtx(() => [_createTextVNode(" Sessions ")]),
          default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(item.sessions), 1)]),
          _: 2
        }, 1024)]),
        footer: _withCtx(() => [_createVNode(_component_TablePagination)]),
        _: 1
      }, 8, ["conf"]);
    };
  }
};