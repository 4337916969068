import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_BaseBadge = _resolveComponent("BaseBadge");
  return _openBlock(), _createBlock(_component_BaseBadge, {
    class: "ml-3",
    color: "blue",
    size: "sm"
  }, {
    tooltip: _withCtx(() => [_createTextVNode(" This feature is currently only for ércule agency use. ")]),
    default: _withCtx(() => [_createTextVNode(" ércule internal ")]),
    _: 1
  });
}