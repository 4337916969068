import "core-js/modules/es.array.push.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef } from "vue";
const _hoisted_1 = {
  class: "min-h-screen bg-gray-50 pb-16"
};
const _hoisted_2 = {
  class: "sticky bg-white top-0 shadow z-40"
};
const _hoisted_3 = {
  class: "bg-white p-6 pl-0"
};
const _hoisted_4 = {
  class: "sm:flex sm:items-center sm:justify-between"
};
const _hoisted_5 = {
  class: "flex overflow-hidden"
};
const _hoisted_6 = {
  key: 0,
  class: "mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left overflow-hidden pr-6"
};
const _hoisted_7 = {
  class: "text-xl font-bold text-gray-900 sm:text-2xl truncate"
};
const _hoisted_8 = ["title"];
const _hoisted_9 = {
  key: 0
};
const _hoisted_10 = {
  class: "bg-white shadow rounded-lg divide-y divide-gray-200 max-w-4xl mx-auto my-10"
};
const _hoisted_11 = {
  class: "bg-gray-50 px-4 py-5 sm:px-6 rounded-t-lg"
};
const _hoisted_12 = {
  class: "-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
};
const _hoisted_13 = {
  class: "ml-4 mt-2"
};
const _hoisted_14 = {
  class: "text-lg leading-6 font-medium text-gray-900"
};
const _hoisted_15 = {
  key: 0,
  class: "ml-4 mt-2 flex-shrink-0"
};
const _hoisted_16 = {
  class: "grid grid-cols-1 md:grid-cols-3 md:divide-x"
};
const _hoisted_17 = {
  class: "p-10"
};
import dayjs from "dayjs";
import { mean } from "simple-statistics";
import { getLibraryGscQuery, getLibraryGscWithRegex, getLibraryTopic, deleteLibraryTopic } from "@/api/library";

import { useRoute, useRouter } from 'vue-router';
import { useLibraryStore, useNotificationStore, useUserStore } from '@/stores';
import { ref, reactive, computed, onMounted } from 'vue';
import { LocalTableConfig, getTopicRegexObject } from '@/utils';
export default {
  __name: 'TopicSingle',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const libraryStore = useLibraryStore();
    const notificationStore = useNotificationStore();
    const userStore = useUserStore();
    const showDeleteModal = ref(false);
    const clicksActive = ref(true);
    const impressionsActive = ref(true);
    const topic = ref(undefined);
    const pageConf = reactive(new LocalTableConfig({
      key: "canonical_page_id",
      by: "searchData.impressions",
      pageSize: 10,
      checkboxes: true,
      copyFormat: "topicExplorerPages",
      getAsync: getPages
    }));
    const topicClicks = ref(undefined);
    const topicImpressions = ref(undefined);
    const topicPositions = ref(undefined);
    const topicGraphLabels = ref(undefined);
    const chartData = computed(() => {
      if (!topicClicks.value && !topicImpressions.value && !topicPositions.value && !topicGraphLabels.value) return undefined;
      return {
        datasets: [{
          label: "Clicks",
          data: topicClicks.value,
          borderColor: "#0891B2",
          hidden: !clicksActive.value,
          backgroundColor: "rgba(8, 145, 178, 0.1)"
        }, {
          label: "Impressions",
          data: topicImpressions.value,
          borderColor: "#2563EB",
          hidden: !impressionsActive.value,
          backgroundColor: "rgba(37, 99, 235, 0.1)"
        }],
        labels: topicGraphLabels.value
      };
    });
    onMounted(async () => {
      topic.value = await getLibraryTopic(route.params.libraryId, route.params.topicSlug);
      if (!topic.value) return;
      pageConf.get();
      getTopicMonthlyData();
    });
    async function getKeywords() {
      return await getLibraryGscQuery(libraryStore.id, {
        rowLimit: 100,
        regex: topic.value.regex
      });
    }
    async function getPages() {
      return await libraryStore.getTopicPages(getTopicRegexObject(topic.value.regex, "regex"));
    }
    async function deleteTopic() {
      showDeleteModal.value = false;
      const res = await deleteLibraryTopic(route.params?.libraryId, topic.value.slug);
      if (res) {
        notificationStore.setNotification("Topic deleted", `Topic "${topic.value?.name}" was deleted`, "red");
        router.push({
          name: "TopicReports"
        });
      } else {
        notificationStore.setNotification("Topic could not be deleted", `Topic "${topic.value?.name}" could not be deleted`, "red");
      }
    }
    async function getTopicMonthlyData() {
      const months = [];
      const labels = [];
      const yearBody = {
        startDate: dayjs().subtract(11, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().subtract(0, "month").endOf("month").format("YYYY-MM-DD"),
        dimensions: ["DATE"],
        rowLimit: "400",
        regex: topic.value.regex
      };
      const gscTopicDays = await getLibraryGscWithRegex(libraryStore.id, yearBody);
      for (var i = 11; i >= 0; i--) {
        const currentMonth = dayjs().subtract(i, "month").format("YYYY-MM");
        const currentDays = gscTopicDays.filter(day => dayjs(day.keys[0]).format("YYYY-MM") === currentMonth);
        if (currentDays?.length > 0) {
          months.push({
            clicks: currentDays.reduce((sum, day) => sum + day.clicks, 0),
            impressions: currentDays.reduce((sum, day) => sum + day.impressions, 0),
            position: Math.max(mean(currentDays.map(day => day.position ? day.position : 100)) - 0.5, 1)
          });
        } else {
          months.push({
            clicks: 0,
            impressions: 0,
            position: 100
          });
        }
        labels.push(dayjs().subtract(i, "month").startOf("month").format("MMM YY"));
      }
      topicGraphLabels.value = labels;
      topicGraphLabels.value[11] += "\n(incomplete)";
      topicClicks.value = months.map(month => month.clicks);
      topicImpressions.value = months.map(month => month.impressions);
      topicPositions.value = months.map(month => month.position < 100 ? month.position : "100+");
    }
    return (_ctx, _cache) => {
      const _component_hero_chevron_left_16_solid = _resolveComponent("hero-chevron-left-16-solid");
      const _component_BaseButton = _resolveComponent("BaseButton");
      const _component_BaseModalDelete = _resolveComponent("BaseModalDelete");
      const _component_BaseDataBox = _resolveComponent("BaseDataBox");
      const _component_TopicSingleChartOverview = _resolveComponent("TopicSingleChartOverview");
      const _component_KeywordsBaseTable = _resolveComponent("KeywordsBaseTable");
      const _component_TopicSingleTablePages = _resolveComponent("TopicSingleTablePages");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("a", {
        class: "w-20 mx-6 flex items-center justify-center hover:text-indigo-600",
        onClick: _cache[0] || (_cache[0] = $event => _unref(router).go(-1))
      }, [_createVNode(_component_hero_chevron_left_16_solid, {
        class: "w-6 h-6"
      })]), _unref(topic) ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("p", _hoisted_7, _toDisplayString(_unref(topic).name), 1), _createElementVNode("p", {
        class: "text-sm font-medium text-gray-600 truncate",
        title: _unref(topic).string
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(topic).terms, (term, index) => {
        return _openBlock(), _createElementBlock("span", {
          key: term
        }, [index != 0 ? (_openBlock(), _createElementBlock("span", _hoisted_9, ", ")) : _createCommentVNode("", true), _createTextVNode("\"" + _toDisplayString(term) + "\"", 1)]);
      }), 128))], 8, _hoisted_8)])) : _createCommentVNode("", true)]), _unref(topic) ? (_openBlock(), _createBlock(_component_BaseButton, {
        key: 0,
        styles: "secondary",
        onClick: _cache[1] || (_cache[1] = $event => showDeleteModal.value = true)
      }, {
        default: _withCtx(() => [_createTextVNode(" Delete Topic ")]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_BaseModalDelete, {
        open: _unref(showDeleteModal),
        "onUpdate:open": _cache[2] || (_cache[2] = $event => _isRef(showDeleteModal) ? showDeleteModal.value = $event : null),
        onDelete: deleteTopic
      }, {
        title: _withCtx(() => [_createTextVNode(" Delete Topic ")]),
        description: _withCtx(() => [_createTextVNode(" Are you sure you want to delete the topic \"" + _toDisplayString(_unref(topic)?.name) + "\"? ", 1)]),
        _: 1
      }, 8, ["open"])])])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, [_createElementVNode("h3", _hoisted_14, [_createTextVNode(" Performance "), _createElementVNode("span", null, "– " + _toDisplayString(_unref(dayjs)().format('MMMM YYYY')), 1)])]), _unref(libraryStore).gscSiteurl && _unref(topic) ? (_openBlock(), _createElementBlock("div", _hoisted_15, [_createVNode(_component_BaseButton, {
        href: `https://search.google.com/search-console/performance/search-analytics?authuser=${_unref(userStore).user.email}&resource_id=${encodeURIComponent(_unref(libraryStore).gscSiteurl)}&breakdown=query&query=~${encodeURIComponent(_unref(topic).regex)}&num_of_days=28&compare_date=PREV`
      }, {
        default: _withCtx(() => [_createTextVNode(" Search Console ")]),
        _: 1
      }, 8, ["href"])])) : _createCommentVNode("", true)])]), _createElementVNode("div", null, [_createElementVNode("dl", _hoisted_16, [_createVNode(_component_BaseDataBox, {
        active: _unref(clicksActive),
        "onUpdate:active": _cache[3] || (_cache[3] = $event => _isRef(clicksActive) ? clicksActive.value = $event : null),
        data: _unref(topicClicks)?.length > 0 ? _unref(topicClicks)[11] : undefined,
        color: "aqua"
      }, {
        title: _withCtx(() => [_createTextVNode(" Clicks ")]),
        tooltip: _withCtx(() => [_createTextVNode(" Total organic clicks in the last month for all keywords in this topic. Source: GSC ")]),
        _: 1
      }, 8, ["active", "data"]), _createVNode(_component_BaseDataBox, {
        active: _unref(impressionsActive),
        "onUpdate:active": _cache[4] || (_cache[4] = $event => _isRef(impressionsActive) ? impressionsActive.value = $event : null),
        data: _unref(topicImpressions) ? _unref(topicImpressions)[11] : undefined,
        color: "blue"
      }, {
        title: _withCtx(() => [_createTextVNode(" Impressions ")]),
        tooltip: _withCtx(() => [_createTextVNode(" Total search impressions in the last month for all keywords in this topic. Source: GSC ")]),
        _: 1
      }, 8, ["active", "data"]), _createVNode(_component_BaseDataBox, {
        data: _unref(topicPositions) ? _unref(topicPositions)[11] : undefined
      }, {
        title: _withCtx(() => [_createTextVNode(" Avg. Rank ")]),
        tooltip: _withCtx(() => [_createTextVNode(" The average keyword rank in the last month across all keywords in this topic. Source: GSC ")]),
        _: 1
      }, 8, ["data"])])]), _createElementVNode("div", _hoisted_17, [_createVNode(_component_TopicSingleChartOverview, {
        class: "h-48",
        "chart-data": _unref(chartData)
      }, null, 8, ["chart-data"])])]), _unref(topic) && _unref(libraryStore).gscSiteurl ? (_openBlock(), _createBlock(_component_KeywordsBaseTable, {
        key: 0,
        get: getKeywords,
        class: "legacy-table slim max-w-4xl mx-auto my-10"
      })) : _createCommentVNode("", true), _createVNode(_component_TopicSingleTablePages, {
        conf: _unref(pageConf),
        "onUpdate:conf": _cache[5] || (_cache[5] = $event => _isRef(pageConf) ? pageConf.value = $event : null),
        class: "legacy-table slim max-w-4xl mx-auto my-10"
      }, null, 8, ["conf"])]);
    };
  }
};