import { api } from "@/api"
import escapeStringRegexp from "escape-string-regexp"
import { getGscPageRegexObject } from "@/utils"

const typeMap = {
	"EXCLUDE": "-", 
	"INCLUDE": "+", 
}

export async function getLibraryStatus(workspaceId, libraryId) {
	const res = await api.get(`/api/workspaces/${workspaceId}/libraries/${libraryId}/`)
	if (!res?.data) return

	const library = res.data
	library.workspaceId = library.workspace
	library.workspacePlanType = library.workspace_plan_type
	library.pageCount = library.page_count
	library.maxViewedPage = library.max_page_views || 10000
	library.ga4PropertyId = library.ga4_property_id === "" ? null : library.ga4_property_id
	library.uaPropertyId = library.ua_property_id === "" ? null : library.ua_property_id
	library.gscSiteurl = library.gsc_siteurl === "" ? null : library.gsc_siteurl
	library.hostnameFilterValues = library.hostname_filter_values || []
	library.pathFilterValues = library.path_filter_values || []
	library.hostnameFilterType = library.hostname_filter_type
	library.pathFilterType = library.path_filter_type

	// build a single visually displayed filter list
	library.filters = []

	library.hostnameFilterValues.forEach(filter=>{
		library.filters.push(typeMap[library.hostnameFilterType] + " " + filter)
	})

	library.pathFilterValues.forEach(filter=>{
		library.filters.push(typeMap[library.pathFilterType] + " " + filter)
	})

	if (library.filters.length - 10 > 0) {
		const count = library.filters.length - 10
		library.filters = library.filters.slice(0, 10)
		library.filters.push(`And ${count} more...`)
	}


	// build URL filters for our open endpoint to match our default GA filters
	library.gscUrlFilters = []

	if (library.hostnameFilterValues?.length > 0) {
		const hostnameString = library.hostnameFilterValues.map(hostname => escapeStringRegexp(hostname)).join("|")

		library.gscUrlFilters.push({
			"dimension": "PAGE",
			"operator": library.hostnameFilterType === "EXCLUDE" ? "EXCLUDING_REGEX" : "INCLUDING_REGEX",
			"expression": `^https?:\\/\\/(www\\.)?(${hostnameString})(\\/.*)?$`
		})
	}

	if (library.pathFilterValues?.length > 0) {
		const pathRegexObject = getGscPageRegexObject(library.pathFilterValues)

		library.gscUrlFilters.push({
			"dimension": "PAGE",
			"operator": library.pathFilterType === "EXCLUDE" ? "EXCLUDING_REGEX" : "INCLUDING_REGEX",
			"expression": pathRegexObject.regex,
		})
	}

	return library
}