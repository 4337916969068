import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 0
};
import { numDisplay } from "@/utils";

import { computed } from 'vue';
export default {
  __name: 'DocumentTableGroupTechnical',
  props: {
    options: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const data = computed(() => props.options.page.speed.mobile);
    return (_ctx, _cache) => {
      const _component_DocumentTableRowHeader = _resolveComponent("DocumentTableRowHeader");
      const _component_DocumentTableRow = _resolveComponent("DocumentTableRow");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_DocumentTableRowHeader, null, {
        default: _withCtx(() => [_createTextVNode("Technical")]),
        _: 1
      }), _createVNode(_component_DocumentTableRow, null, {
        label: _withCtx(() => [_createTextVNode(" Mobile Speed ")]),
        default: _withCtx(() => [_unref(data).score ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_unref(data).score) + "/100", 1)) : _createCommentVNode("", true)]),
        _: 1
      }), _createVNode(_component_DocumentTableRow, null, {
        label: _withCtx(() => [_createTextVNode(" FCP ")]),
        default: _withCtx(() => [_unref(data).fcpTime ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(numDisplay)(_unref(data).fcpTime / 1000, 'm')) + " s", 1)) : _createCommentVNode("", true)]),
        _: 1
      }), _createVNode(_component_DocumentTableRow, null, {
        label: _withCtx(() => [_createTextVNode(" LCP ")]),
        default: _withCtx(() => [_unref(data).lcpTime ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(numDisplay)(_unref(data).lcpTime / 1000, 'm')) + " s", 1)) : _createCommentVNode("", true)]),
        _: 1
      })], 64);
    };
  }
};