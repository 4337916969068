import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache) {
  const _component_DocumentTableCell = _resolveComponent("DocumentTableCell");
  return _openBlock(), _createElementBlock("tr", null, [_createVNode(_component_DocumentTableCell, {
    colspan: 12,
    style: {
      "background-color": "#f2f2f2",
      "font-weight": "bold"
    }
  }, {
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  })]);
}