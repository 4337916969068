import axios from "axios"
import { defaultErrorHandling } from "@/api"

export async function getPageSpeed(pageUrl, type) {
	const encodedUrl = encodeURIComponent("https://" + pageUrl)
	const requestUrl = `https://www.googleapis.com/pagespeedonline/v5/runPagespeed?key=AIzaSyAa4YpZ8xaBXGBmrA-8bGA9gpGWrwPeeNg&strategy=${type}&url=${encodedUrl}`
	const res = await axios.get(requestUrl).catch(defaultErrorHandling)

	if (res?.data) {
		return {
			score: res.data.lighthouseResult?.categories?.performance?.score * 100,
			fcpTime: res.data.lighthouseResult?.audits["first-contentful-paint"].numericValue,
			lcpTime: res.data.lighthouseResult?.audits["largest-contentful-paint"].numericValue,
			reportLink: "",
		}
	} else {
		return {
			score: -1,
		}
	}
}
