import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, isRef as _isRef, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  id: "content-body",
  class: "bg-gray-100 min-h-screen"
};
const _hoisted_2 = {
  class: "sticky bg-white top-0 shadow z-10"
};
const _hoisted_3 = {
  class: "bg-white p-6 pl-0"
};
const _hoisted_4 = {
  class: "sm:flex sm:items-center sm:justify-between"
};
const _hoisted_5 = {
  class: "sm:flex overflow-hidden"
};
const _hoisted_6 = {
  key: 0,
  class: "mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left overflow-hidden pr-6"
};
const _hoisted_7 = {
  class: "group flex items-center h-5 pr-3"
};
const _hoisted_8 = {
  class: "text-sm font-medium text-gray-600 truncate"
};
const _hoisted_9 = ["href"];
const _hoisted_10 = {
  class: "group flex items-center pr-3"
};
const _hoisted_11 = {
  class: "text-xl font-bold text-gray-900 sm:text-2xl truncate"
};
const _hoisted_12 = ["href"];
const _hoisted_13 = {
  key: 0,
  class: "animate-pulse w-[600px] mr-auto h-6 my-4 bg-gray-100 rounded-full"
};
const _hoisted_14 = {
  key: 1,
  class: "mt-5 flex justify-center sm:mt-0 flex-none"
};
const _hoisted_15 = {
  class: "max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10"
};
const _hoisted_16 = {
  class: "max-w-3xl mx-auto"
};
import { getPage } from "@/api/page";

import { useRoute, useRouter } from 'vue-router';
import { useLibraryStore, useUserStore, useNotificationStore } from '@/stores';
import { ref, onMounted, watch } from 'vue';
export default {
  __name: 'PageSingle',
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const libraryStore = useLibraryStore();
    const userStore = useUserStore();
    const notificationStore = useNotificationStore();
    const page = ref(undefined);
    onMounted(loadPage);
    watch(() => libraryStore.status, loadPage);
    async function loadPage() {
      if (!libraryStore.status) return;
      const res = await getPage(route.params.libraryId, route.params.pageId);
      if (!res) {
        notificationStore.setNotification("Library Page Not Found", "We were unable to find this page URL in your data.", "yellow");
      } else {
        page.value = res;
      }
    }
    return (_ctx, _cache) => {
      const _component_hero_chevron_left_16_solid = _resolveComponent("hero-chevron-left-16-solid");
      const _component_BaseCopyIcon = _resolveComponent("BaseCopyIcon");
      const _component_BaseButton = _resolveComponent("BaseButton");
      const _component_PageSingleModuleAnalytics = _resolveComponent("PageSingleModuleAnalytics");
      const _component_PageSingleModuleKeywords = _resolveComponent("PageSingleModuleKeywords");
      const _component_PageSingleModuleSpeed = _resolveComponent("PageSingleModuleSpeed");
      const _component_PageSingleModuleMeta = _resolveComponent("PageSingleModuleMeta");
      const _component_PageSingleTableReferrers = _resolveComponent("PageSingleTableReferrers");
      const _component_PageSingleTableReferrals = _resolveComponent("PageSingleTableReferrals");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createElementVNode("a", {
        class: "w-20 mx-6 flex items-center justify-center hover:text-indigo-600",
        onClick: _cache[0] || (_cache[0] = $event => _unref(router).go(-1))
      }, [_createVNode(_component_hero_chevron_left_16_solid, {
        class: "w-6 h-6"
      })]), _unref(page)?.meta ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("p", _hoisted_8, [_createElementVNode("a", {
        href: 'https://' + _unref(page).canonical_page_id,
        target: "_blank"
      }, _toDisplayString(_unref(page).canonical_page_id), 9, _hoisted_9)]), _createVNode(_component_BaseCopyIcon, {
        class: "ml-2 hidden group-hover:block flex-none",
        data: 'https://' + _unref(page).canonical_page_id,
        size: "sm",
        title: "Copy page URL to clipboard."
      }, null, 8, ["data"])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("p", _hoisted_11, [_createElementVNode("a", {
        href: 'https://' + _unref(page).canonical_page_id,
        target: "_blank"
      }, _toDisplayString(_unref(page).meta.title), 9, _hoisted_12)]), _createVNode(_component_BaseCopyIcon, {
        class: "ml-3 hidden group-hover:block flex-none",
        data: _unref(page).meta.title,
        title: "Copy page title to clipboard."
      }, null, 8, ["data"])])])) : _createCommentVNode("", true)]), !_unref(page)?.meta ? (_openBlock(), _createElementBlock("div", _hoisted_13)) : _createCommentVNode("", true), _unref(page)?.meta ? (_openBlock(), _createElementBlock("div", _hoisted_14, [_createVNode(_component_BaseButton, {
        enabled: !!_unref(page)?.canonical_page_id,
        href: 'https://' + _unref(page).canonical_page_id,
        styles: "primary"
      }, {
        default: _withCtx(() => [_createTextVNode(" Visit Page ")]),
        _: 1
      }, 8, ["enabled", "href"])])) : _createCommentVNode("", true)])])]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createVNode(_component_PageSingleModuleAnalytics, {
        page: _unref(page)
      }, null, 8, ["page"]), _unref(page) && _unref(libraryStore).gscSiteurl ? (_openBlock(), _createBlock(_component_PageSingleModuleKeywords, {
        key: 0,
        page: _unref(page),
        "onUpdate:page": _cache[1] || (_cache[1] = $event => _isRef(page) ? page.value = $event : null)
      }, null, 8, ["page"])) : _createCommentVNode("", true), _unref(page) ? (_openBlock(), _createBlock(_component_PageSingleModuleSpeed, {
        key: 1,
        page: _unref(page),
        "onUpdate:page": _cache[2] || (_cache[2] = $event => _isRef(page) ? page.value = $event : null)
      }, null, 8, ["page"])) : _createCommentVNode("", true), _unref(page) ? (_openBlock(), _createBlock(_component_PageSingleModuleMeta, {
        key: 2,
        page: _unref(page),
        "onUpdate:page": _cache[3] || (_cache[3] = $event => _isRef(page) ? page.value = $event : null)
      }, null, 8, ["page"])) : _createCommentVNode("", true), _unref(page) && _unref(userStore).isAdmin ? (_openBlock(), _createBlock(_component_PageSingleTableReferrers, {
        key: 3,
        page: _unref(page),
        "onUpdate:page": _cache[4] || (_cache[4] = $event => _isRef(page) ? page.value = $event : null),
        class: "legacy-table slim my-10"
      }, null, 8, ["page"])) : _createCommentVNode("", true), _unref(page) && _unref(userStore).isAdmin ? (_openBlock(), _createBlock(_component_PageSingleTableReferrals, {
        key: 4,
        page: _unref(page),
        "onUpdate:page": _cache[5] || (_cache[5] = $event => _isRef(page) ? page.value = $event : null),
        class: "legacy-table slim my-10"
      }, null, 8, ["page"])) : _createCommentVNode("", true)])])]);
    };
  }
};