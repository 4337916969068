import { useModel as _useModel } from 'vue';
import { createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createSlots as _createSlots, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "copy"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = {
  key: 1,
  class: "bg-gray-100 animate-pulse rounded-full h-5 w-[220px]"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1,
  class: "bg-gray-100 animate-pulse rounded-full h-5 w-[220px]"
};
const _hoisted_6 = {
  key: 0,
  class: "bg-gray-100 rounded-full px-3 py-1"
};
const _hoisted_7 = {
  key: 1,
  class: "bg-gray-100 animate-pulse rounded-full h-5 w-[220px]"
};
import html2canvas from "html2canvas";
import { postGoogleDriveFile } from "@/api/user";
import { numDisplay, vueComponentToHTML, buildGscUrl, buildGaUrl } from "@/utils";
import seoUpdateComponent from "@/components/utility/DocumentSeoUpdate.vue";
import croUpdateComponent from "@/components/utility/DocumentCroUpdate.vue";

import { useUserStore, useLibraryStore } from '@/stores';
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
export default {
  __name: 'PageSingleModuleMeta',
  props: {
    "page": {
      type: Object,
      required: true
    },
    "pageModifiers": {}
  },
  emits: ["update:page"],
  setup(__props) {
    const userStore = useUserStore();
    const route = useRoute();
    const libraryStore = useLibraryStore();
    const page = _useModel(__props, "page");
    const generatingUpdate = ref(false);
    onMounted(() => {
      page.value.getMetaScrape();
    });
    async function generateUpdate(updateType) {
      generatingUpdate.value = true;
      await page.value.getGsc();
      await page.value.getMetaScrape();
      await page.value.getReferrers();
      await page.value.getReferrals();
      const vueComponent = updateType === "CRO" ? croUpdateComponent : seoUpdateComponent;
      const trafficSnapshot = await html2canvas(document.querySelector("#page-traffic-module"));
      const collectionsSnapshot = await html2canvas(document.querySelector("#page-bubble-collections"), {
        onclone: el => {
          const collectionBubbles = el.querySelectorAll("#page-bubble-collections a");
          for (let i = 0; i < collectionBubbles.length; i++) {
            collectionBubbles[i].style.fontSize = "12px";
          }
        }
      });
      const fileBody = await vueComponentToHTML(vueComponent, {
        page: page.value,
        pageBody: page.value.metaScrape?.body,
        snapshots: {
          traffic: trafficSnapshot.toDataURL(),
          collections: collectionsSnapshot.toDataURL()
        },
        links: {
          app: window.location.href,
          pageSpeed: page.value.pageSpeedLink,
          ga: buildGaUrl(page.value, libraryStore.ga4PropertyId, userStore.user.email),
          gsc: buildGscUrl(page.value.canonical_page_id, libraryStore.gscSiteurl, userStore.user.email)
        }
      });
      const res = await postGoogleDriveFile(route.params.workspaceId, fileBody, `${updateType} Update – ${page.value.meta.url}`);
      if (res?.data?.document_id) {
        window.open("https://docs.google.com/document/d/" + res.data.document_id, "_blank");
      }
      generatingUpdate.value = false;
    }
    return (_ctx, _cache) => {
      const _component_BaseButton = _resolveComponent("BaseButton");
      const _component_PageBubbleGroupCollections = _resolveComponent("PageBubbleGroupCollections");
      const _component_BaseStackedListItem = _resolveComponent("BaseStackedListItem");
      const _component_PageBubbleGroupFlags = _resolveComponent("PageBubbleGroupFlags");
      const _component_BaseStackedList = _resolveComponent("BaseStackedList");
      return page.value ? (_openBlock(), _createBlock(_component_BaseStackedList, {
        key: 0
      }, _createSlots({
        title: _withCtx(() => [_createTextVNode(" Content Overview ")]),
        default: _withCtx(() => [_createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" Collections ")]),
          default: _withCtx(() => [_createTextVNode(), _createVNode(_component_PageBubbleGroupCollections, {
            id: "page-bubble-collections",
            item: page.value
          }, null, 8, ["item"])]),
          _: 1
        }), _createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" Recommendations ")]),
          default: _withCtx(() => [_createTextVNode(), _createVNode(_component_PageBubbleGroupFlags, {
            item: page.value
          }, null, 8, ["item"])]),
          _: 1
        }), _createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" URL ")]),
          default: _withCtx(() => [_createTextVNode(), page.value.meta ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("a", {
            href: 'https://' + page.value.canonical_page_id,
            target: "_blank"
          }, _toDisplayString(page.value.canonical_page_id), 9, _hoisted_2)])) : (_openBlock(), _createElementBlock("div", _hoisted_3))]),
          _: 1
        }), _createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" Title ")]),
          default: _withCtx(() => [page.value.meta ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(page.value.meta.title), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_5))]),
          _: 1
        }), _createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" Heading 1 ")]),
          default: _withCtx(() => [_createElementVNode("div", {
            class: _normalizeClass({
              'text-gray-800': page.value.metaScrape?.h1,
              'text-gray-300 italic': !page.value.metaScrape?.h1
            })
          }, _toDisplayString(page.value.metaScrape?.h1 || "[Couldn't be read]"), 3)]),
          _: 1
        }), _createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" Meta Description ")]),
          default: _withCtx(() => [_createElementVNode("div", {
            class: _normalizeClass({
              'text-gray-800': page.value.metaScrape?.description,
              'text-gray-300 italic': !page.value.metaScrape?.description
            })
          }, _toDisplayString(page.value.metaScrape?.description || "[Couldn't be read]"), 3)]),
          _: 1
        }), _createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" First Seen ")]),
          default: _withCtx(() => [page.value?.timeSeries.all.earliestDateDisplay ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(page.value?.timeSeries.all.earliestDateDisplay), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_7))]),
          _: 1
        }), _createVNode(_component_BaseStackedListItem, null, {
          title: _withCtx(() => [_createTextVNode(" Word Count ")]),
          default: _withCtx(() => [_createElementVNode("div", {
            class: _normalizeClass({
              'text-gray-800': page.value.metaScrape?.wordCount,
              'text-gray-300 italic': !page.value.metaScrape?.wordCount
            })
          }, _toDisplayString(page.value.metaScrape?.wordCount ? `${_unref(numDisplay)(page.value.metaScrape?.wordCount)} Words` : "[Couldn't be read]"), 3)]),
          _: 1
        })]),
        _: 2
      }, [_unref(userStore)?.isAdmin ? {
        name: "button",
        fn: _withCtx(() => [_createVNode(_component_BaseButton, {
          enabled: !_unref(generatingUpdate),
          onClick: _cache[0] || (_cache[0] = $event => generateUpdate('SEO'))
        }, {
          default: _withCtx(() => [_createTextVNode(" SEO Update ")]),
          _: 1
        }, 8, ["enabled"]), _createVNode(_component_BaseButton, {
          enabled: !_unref(generatingUpdate),
          class: "ml-3",
          onClick: _cache[1] || (_cache[1] = $event => generateUpdate('CRO'))
        }, {
          default: _withCtx(() => [_createTextVNode(" CRO Update ")]),
          _: 1
        }, 8, ["enabled"])]),
        key: "0"
      } : undefined]), 1024)) : _createCommentVNode("", true);
    };
  }
};